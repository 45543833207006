import {Injectable} from '@angular/core';
import {Calendar, Course, FormData, Personal} from './formData.model';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor(
    private router: Router) {
  }

  private formData: FormData = new FormData();
  private isDateValid = false;
  private isCourseValid = false;
  private isTicketsValid = false;
  private isCartValid = false;
  private isPersonalFormValid = false;

  ticketsArrModified: Array<any> = [];

  orderedItems: any;

  activeSteps: any = {
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
  };

  disableStep: any = {
    step_1: false,
    step_2: true,
    step_3: true,
    step_4: true,
  }

  static ticketCalcData(ticket) {

    const calcData = {
      totalPrice: ticket.totalPrice,
      totalQty: ticket.totalQty,
      available: ticket.available,
    };

    let newTotalPrice = 0;
    let newTotalQty = 0;

    const types = ticket.types;

    for (let i = 0; i < types.length; i++) {
      newTotalQty += types[i].modelVal;
      newTotalPrice += parseFloat(types[i].modelVal) * parseFloat(types[i].price);
    }

    calcData.totalPrice = newTotalPrice;
    calcData.totalQty = newTotalQty;
    calcData.available = newTotalQty > 0;

    return calcData;
  }


  // Set location URL
  setLocationUrl(link: object) {
    this.formData.location = link;
  }

  getLocationUrl(): any {
    return this.formData.location;
  }

  // Menu 'active' Classes
  setActiveStep(step, val) {
    this.activeSteps[step] = val;
  }

  setDisableStep(step, val) {
    this.disableStep[step] = val;
  }

  getActiveStep() {
    return this.activeSteps;
  }

  getDisableStep(){
    return this.disableStep;
  }

  resetActiveSteps() {
    this.activeSteps.step_1 = false;
    this.activeSteps.step_2 = false;
    this.activeSteps.step_3 = false;
    this.activeSteps.step_4 = false;
  }

  // Ordered Items
  setOrderedItems(data) {
    this.orderedItems = data;
  }

  getOrderedItems() {
    return this.orderedItems;
  }

  // Form Service Data
  getFormData(): FormData {
    // Return the entire Form Data
    return this.formData;
  }

  resetFormData(): FormData {
    // Return the form data after all this.* members had been reset
    this.formData.clearAll();
    this.isDateValid = this.isCourseValid = this.isCourseValid = this.isCartValid = this.isPersonalFormValid = this.isTicketsValid = false;
    return this.formData;
  }

  isFormValid() {
    // Return true if all forms had been validated successfully; otherwise, return false
    return this.isDateValid &&
      this.isCourseValid &&
      this.isCartValid &&
      this.isPersonalFormValid &&
      this.isTicketsValid;
  }


  // Calendar
  getSelectedDate(): Calendar {
    return {
      selectedDate: this.formData.date,
      policyAccepted: this.formData.policyAccepted
    };

  }

  setSelectedDate(data: Calendar) {
    this.isDateValid = true;
    this.formData.date = data.selectedDate;
    this.formData.policyAccepted = data.policyAccepted;
  }


  // Course Types
  getCourseType(): Course {
    return {
      course: this.formData.course,
    };
  }

  setCourseType(course: object) {
    this.isCourseValid = true;
    this.formData.course = course;
  }

  resetCourse() {
    this.isCourseValid = false;
    this.formData.clearCourse();
    return this.formData;
  }


  // Personal
  getPersonal(): Personal {

    return {
      user_name: this.formData.user_name,
      user_email: this.formData.user_email,
      user_phone: this.formData.user_phone,
      country_id: this.formData.country_id,
      user_zip: this.formData.user_zip
    };

  }

  setPersonal(data: Personal) {
    this.isPersonalFormValid = true;
    this.formData.user_name = data.user_name;
    this.formData.user_email = data.user_email;
    this.formData.user_phone = data.user_phone;
    this.formData.country_id = data.country_id;
    this.formData.user_zip = data.user_zip;
  }

  resetPersonal() {
    this.isPersonalFormValid = false;
    this.formData.clearPersonal();
    return this.formData;
  }


  // Tickets
  setTicketStructure(apiTickets) {

    // Reset the tickets data, if the user changes to step 2
    if (this.ticketsArrModified.length > 0) {
      this.ticketsArrModified = [];
    }

    if (apiTickets.available_time_slots.length > 0 && apiTickets.available_ticket_types.length > 0) {

      apiTickets.available_time_slots.forEach((slot: any, slotIndex: any) => {

        const slots = {
          id: slot.id,
          created_at: slot.created_at,
          updated_at: slot.updated_at,
          date: slot.date,
          start_at: slot.start_at,
          // end_at: slot.end_at,
          available_tickets: slot.available_tickets,
          calcData: {
            totalPrice: 0,
            totalQty: 0,
            available: false,
          },
          types: []
        };

        apiTickets.available_ticket_types.forEach((type: any, typeIndex: any) => {

          const data = {
            id: type.id,
            created_at: type.created_at,
            updated_at: type.updated_at,
            name: type.name,
            description: type.description,
            price: type.price,
            modelName: 'input__' + type.id,
            modelVal: 0
          };

          slots.types.push(data);
        });

        this.ticketsArrModified.push(slots);

      });
    }

    return this.ticketsArrModified;

  }

  getTicketStructure() {
    return this.ticketsArrModified;
  }

  generateTicketTotalPrice(ticket, type) {

    const ticketData = this.getTicketStructure();

    // find ticket & index
    const ticketModItem = ticketData.filter(item => item.id === ticket.id),
      ticketModIndex = ticketData.findIndex(item => item.id === ticket.id);

    // find type & index => ticket
    const typesModArr = ticketModItem[0].types,
      indexOfType = typesModArr.findIndex(item => item.id === type.id);

    const typePrice = typesModArr[indexOfType].price;
    const typeQty = typesModArr[indexOfType].modelVal;

    const currPrice = this.ticketsArrModified[ticketModIndex].calcData.totalPrice;
    const currQty = this.ticketsArrModified[ticketModIndex].calcData.totalQty;

    this.ticketsArrModified[ticketModIndex].calcData.totalPrice = currPrice - (typePrice * typeQty);
    this.ticketsArrModified[ticketModIndex].calcData.totalQty = currQty - typeQty;

  }

  updateTicketTypeQty(ticketIndex, typeIndex, typeVal) {

    this.ticketsArrModified[ticketIndex].types[typeIndex].modelVal = typeVal;

  }


  // Cart
  incrementTicketToCart(ticket, typeReq) {

    const cart = this.getCart();

    if (cart.length > 0) {

      let newCart = true;

      for (let i = 0; i < cart.length; i++) {

        let newTypes = true;

        const cartItem = cart[i];

        if (cartItem.id === ticket.id) {

          newCart = false;

          // check types

          const cartItemTypes = cartItem.types;

          for (let j = 0; j < cartItemTypes.length; j++) {

            const type = cartItemTypes[j];

            if (type.id === typeReq.id) {
              newTypes = false;
              type.modelVal = typeReq.modelVal;
              break;
            }

          }

          if (newTypes) {
            cartItemTypes.push(typeReq);
          }

          break;

        }

      }

      if (newCart) {
        this.formData.cart.push(ticket);
      }

    } else {
      this.formData.cart.push(ticket);
    }

    // calculate new cart total
    this.checkCartTotal();

  }

  decrementTicketFromCart(ticketID, typeReq) {

    const cart = this.getCart();

    if (cart.length > 0) {

      for (let i = 0; i < cart.length; i++) {

        const ticket = cart[i];

        if (ticket.id === ticketID) {

          // check type qty
          const types = ticket.types;

          let typesEmpty = false;

          for (let j = 0; j < types.length; j++) {

            const type = types[j];

            if (type.id === typeReq.id) {

              if (typeReq.modelVal === 0) {
                types.splice(j, 1);
                typesEmpty = true;
              } else {
                type.modelVal = typeReq.modelVal;
              }

              break;
            }

          }

          if (typesEmpty && types.length === 0) {
            this.formData.cart.splice(i, 1);
          }

          break;
        }

      }

      // calculate new cart total
      this.checkCartTotal();
    }

  }

  removeTypeTicket(ticketReq, typeReq, ticketIndex, typeIndex) {

    const cartItems = this.formData.cart;

    if (cartItems && cartItems.length > 0) {

      const ticketElement = this.formData.cart[ticketIndex];

      if (ticketElement) {

        // remove types
        ticketElement.types.splice(typeIndex, 1);

        // check if types are empty, remove the ticket from cart | calc data based on types
        if (ticketElement.types.length <= 0) {
          cartItems.splice(ticketIndex, 1);
        } else {
          // calc Data for this ticket
          ticketElement.calcData = FormDataService.ticketCalcData(ticketElement);
        }

        // update cart total
        this.checkCartTotal();
      }

    }

    this.generateTicketTotalPrice(ticketReq, typeReq);

    this.syncTicketCartQty(ticketReq, typeReq, 0);
  }

  getCart() {

    if (this.formData.cart.length > 0) {
      this.isCartValid = true;
    }

    return this.formData.cart;
  }

  getCartTotal() {
    return this.formData.cartTotal;
  }

  checkCartTotal() {

    const cart = this.getCart();

    if (cart.length > 0) {

      let total = 0;

      cart.forEach(ticket => {

        ticket.types.forEach(type => {

          total += type.price * type.modelVal;

        });

      });

      this.formData.cartTotal = total;

      return this.formData.cartTotal;
    } else {
      this.cartIsEmpty();
      return;
    }
  }

  resetCart() {
    this.isCartValid = false;
    this.formData.clearCart();
    return this.formData;
  }

  cartIsEmpty() {
    this.setActiveStep('step_4', false);

    this.formData.cartTotal = 0;

    this.router.navigateByUrl('/tickets');
  }


  /**
   * Update tickets qty on step 3 based on values changed on step 4
   * @param ticket
   * @param type
   * @param typeVal
   */
  syncTicketCartQty(ticket, type, typeVal) {
    const ticketData = this.getTicketStructure();

    // find ticket & index
    const ticketModItem = ticketData.filter(item => item.id === ticket.id),
      ticketModIndex = ticketData.findIndex(item => item.id === ticket.id);

    // find type & index => ticket
    const typesModArr = ticketModItem[0].types,
      indexOfType = typesModArr.findIndex(item => item.id === type.id);

    // update this ticket structure
    this.updateTicketTypeQty(ticketModIndex, indexOfType, typeVal);
  }
}
