import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../config/shared-data.service';
import { Ticket, Event } from '../Models/Event.model';
import { DomSanitizer } from '@angular/platform-browser';
import { FormDataService } from '../data/form-data.service';
import { SwiperOptions } from 'swiper';
import { Router, NavigationEnd } from '@angular/router';
//declare let fbq: Function;
@Component({
  selector: 'app-registeration-complete',
  templateUrl: './registeration-complete.component.html',
  styleUrls: ['./registeration-complete.component.scss']
})
export class RegisterationCompleteComponent implements OnInit {

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      hideOnClick: false
    }
  };

  selectedEvents = new Array<Event>();
  totalTickets = 1;
  totalTicketsFree: number;
  location = '';
  prettyLocation = '';
  urlLocation = '';
  totalPrice = 0;
  loc = '';
  whatsapp: any;
  email = '';
  tmp = '';
  discount_code = '';
  events_date = '';
  constructor(private sharedService: SharedDataService, private router: Router, private sanitizer: DomSanitizer, public formDataService: FormDataService) {
    // router.events.subscribe((y: NavigationEnd) => {
    //   if (y instanceof NavigationEnd) {
    //     fbq('track', 'Purchase', {currency: "EUR", value: parseFloat(localStorage.getItem('total_price'))});
    //     fbq('track', 'CompleteRegistration');
    //   }
    // })
  }

  ngOnInit() {
    localStorage.setItem("registration_completed", "true");
    this.selectedEvents = JSON.parse(localStorage.getItem('order'))['events'];
    //this.selectedEvents = this.sharedService.selectedEvents;

    this.totalTickets = parseInt(localStorage.getItem('total_tickets'));
    this.totalTicketsFree = parseInt(localStorage.getItem('total_tickets_free'));
    this.totalTickets = this.totalTickets + this.totalTicketsFree;

    this.location = localStorage.getItem('location');
    this.totalPrice = parseFloat(localStorage.getItem('total_price'));

    if (localStorage.getItem('discount_code')) {
      this.discount_code = localStorage.getItem('discount_code');
    }
    if (localStorage.getItem('pretty_location')) {
      this.prettyLocation = localStorage.getItem('pretty_location');
    }
    else {
      this.prettyLocation = localStorage.getItem('location');
    }

    if (this.discount_code) {
      this.events_date = localStorage.getItem('events_dates');
      this.loc = 'https://kalata.it/revelia/' + this.location + '/?discount=' + this.discount_code;
      this.urlLocation = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fkalata.it%2Frevelia%2F' + this.location + '%2F?discount=' + this.discount_code + '&amp&quote=' + "Con%20Revelia%20possiamo%20finalmente%20scoprire%20insieme%20%22" + this.prettyLocation + "%22!%0D%0ASe%20partecipi%20anche%20tu%2C%20la%20visita%20sar%C3%A0%20confermata!%20Prenota%20subito%20una%20tra%20queste%20date%3A%20" + this.events_date;
      this.urlLocation += "%20e%20usufruisci%20del%20codice%20sconto%20" + this.discount_code;

      this.whatsapp = this.sanitizer.bypassSecurityTrustUrl("https://wa.me/?text=Con%20Revelia%20possiamo%20finalmente%20scoprire%20insieme%20%22" + this.prettyLocation + "%22!%0D%0ASe%20partecipi%20anche%20tu%2C%20la%20visita%20sar%C3%A0%20confermata!%20Prenota%20subito%20una%20tra%20queste%20date%3A%20" + this.events_date + "%20e%20usufruisci%20del%20codice%20sconto%20" + this.discount_code + '%0D%0A%0D%0A' + this.loc);

      this.email = "mailto:?body=Con%20Revelia%20possiamo%20finalmente%20scoprire%20insieme%20%22" + this.prettyLocation + "%22!%0D%0ASe%20partecipi%20anche%20tu%2C%20la%20visita%20sar%C3%A0%20confermata!%20Prenota%20subito%20una%20tra%20queste%20date%3A%20" + this.events_date;
      this.email += "%20e%20usufruisci%20del%20codice%20sconto%20" + this.discount_code + '%0D%0A%0D%0A' + this.loc;
    } else {
      this.loc = 'https://kalata.it/revelia/' + this.location + '/';
      this.urlLocation = 'https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fkalata.it%2Frevelia%2F' + this.location + "&amp&quote=Ho%20appena%20scoperto%20la%20piattaforma%20web%20%22Revelia%20-%20l\'arte%20svelata%22%20che%20mi%20ha%20dato%20la%20possibilit%C3%A0%20di%20scegliere%20fino%20ad%20un%20massimo%20di%20tre%20date%20in%20cui%20visitare%20questo%20straordinario%20bene%20culturale%3A%0D%0A" + this.loc + "%0D%0AOpziona%20anche%20tu%20le%20date%20che%20preferisci%3A%20la%20prima%20che%20raggiunger%C3%A0%20il%20numero%20minimo%20di%20partecipanti%20sar%C3%A0%20confermata!";
      this.whatsapp = this.sanitizer.bypassSecurityTrustUrl("https://wa.me/?text=Ho%20appena%20scoperto%20la%20piattaforma%20web%20%22Revelia%20-%20l'arte%20svelata%22%20che%20mi%20ha%20dato%20la%20possibilit%C3%A0%20di%20scegliere%20fino%20ad%20un%20massimo%20di%20tre%20date%20in%20cui%20visitare%20questo%20straordinario%20bene%20culturale%3A%0D%0A" + this.loc + "%0D%0AOpziona%20anche%20tu%20le%20date%20che%20preferisci%3A%20la%20prima%20che%20raggiunger%C3%A0%20il%20numero%20minimo%20di%20partecipanti%20sar%C3%A0%20confermata!");
      this.email = "mailto:?body=Ho%20appena%20scoperto%20la%20piattaforma%20web%20%22Revelia%20-%20l'arte%20svelata%22%20che%20mi%20ha%20dato%20la%20possibilit%C3%A0%20di%20scegliere%20fino%20ad%20un%20massimo%20di%20tre%20date%20in%20cui%20visitare%20questo%20straordinario%20bene%20culturale%3A%0D%0A" + this.loc + "%0D%0AOpziona%20anche%20tu%20le%20date%20che%20preferisci%3A%20la%20prima%20che%20raggiunger%C3%A0%20il%20numero%20minimo%20di%20partecipanti%20sar%C3%A0%20confermata!";
    }


    this.formDataService.resetActiveSteps();
    this.sharedService.saveSelected(new Array<Event>());

    // CLEAN UP THE LOCAL STORAGE
    localStorage.removeItem('ticket_conf_id');
    localStorage.removeItem('ticket_free_conf_id');
    localStorage.removeItem('order');
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  checkDate(date) {
    var today = new Date().toJSON().slice(0, 10);
    return date >= today;
  }

}

