import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '../config/shared-data.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnDestroy {
  activeSteps: any;
  disableSteps:any;
  location: string;

  constructor(private formDataService: FormDataService, private router: Router, private sharedService: SharedDataService, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.activeSteps = this.formDataService.getActiveStep();
    this.disableSteps = this.formDataService.getDisableStep();
  }

  ngOnDestroy() {
  }

  navigate(link: string, name: string) {
    this.location = localStorage.getItem('location');
    switch (name) {
      case 'how-it-works':
        this.router.navigateByUrl(link + '/' +this.location);
        break;
      case 'calendar':
        this.router.navigateByUrl(link + '/' +this.location);
        break;
      case 'tickets':
        if (this.sharedService.selectedEvents.length > 0 && this.sharedService.privacyAccepted) {
          this.router.navigateByUrl(link + '/' +this.location);
        }
        break;
      case 'payment':
        this.router.navigateByUrl(link);
        break;
      default:
      this.router.navigateByUrl(link);
    }
  }

}
