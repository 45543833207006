export class Guide {
  id = '';
  created_at: number;
  updated_at: number;
  first_name = '';
  last_name = '';
  email = '';
  phone_number: number;
  fiscal_code = '';
}
export class DateConfiguration {
    id = '';
    created_at: number;
    updated_at: number;
    date_event = '';
    start_at = '';
    end_at = '';
    max_seats: number;
    min_seats: number;
    expire_at = '';
    guide: Guide;
}
export class Event {
  id = '';
  created_at: number;
  updated_at: number;
  status = '';
  available_tickets: number;
  percentage_completion_tickets = 0;
  tickets_to_confirm = 1;
  date_configuration: DateConfiguration;
}
export class Ticket {
    id = '';
    created_at: number;
    updated_at: number;
    price:number;
    quantity: number;
}
