import {Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild} from '@angular/core';
import {FormDataService} from '../data/form-data.service';
import {SharedDataService} from '../config/shared-data.service';
import {ApiDataService} from '../api-data.service';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import { Event, Ticket } from '../Models/Event.model';
import { MatTooltip } from '@angular/material';
//declare let fbq:Function;
@Component({
  selector: 'app-tab-tickets',
  templateUrl: './tab-tickets.component.html',
  styleUrls: ['./tab-tickets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabTicketsComponent implements OnInit {


  isLoading = false;

  apiData: any = {date: '', course: ''};

  ticketsArrModified: Array<any> = [];

  ticketsQuantity = 0;
  ticketsQuantityFree = 0;
  totalPrice = 0;
  cartItems: any;
  selectedEvents = new Array<Event>();
  location = '';
  prettyLocation = '';
  tickets = new Array<Ticket>();
  ticket_not_free:any;
  maxTicket: number;
  discount_code_error: string;
  discount_code: string;
  total_amount: string;
  discount: string;
  disabled_tooltip: boolean = true;
  @ViewChild('tooltip') matTooltip: MatTooltip;
  
  constructor(private formDataService: FormDataService,
              private sharedService: SharedDataService,
              public apiService: ApiDataService,
              private router: Router,
              private cdRef: ChangeDetectorRef,
              private route: ActivatedRoute) {
                // router.events.subscribe((y: NavigationEnd) => {
                //   if(y instanceof NavigationEnd){
                //     fbq('track', 'InitiateCheckout');
                //   }
                // })   
    // this.apiData = {
    //   date: this.formDataService.getSelectedDate().selectedDate,
    //   course: this.formDataService.getCourseType().course,
    // };

  }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.sharedService.location = params.location;
      this.location =  this.sharedService.location;
    });
    // set active tab
    this.formDataService.resetActiveSteps();
    this.formDataService.setActiveStep('step_3', true);
    this.formDataService.setDisableStep('step_2', false);
    this.formDataService.setDisableStep('step_4', true);
    
    this.formDataService.activeSteps.step_3 = true;
    // this.selectedEvents = this.sharedService.selectedEvents;
    this.selectedEvents = JSON.parse(localStorage.getItem('selected_events'));
    this.tickets = JSON.parse(localStorage.getItem('tickets'));
    for(let tic of this.tickets){
      if(tic.price != 0){
        localStorage.setItem('ticket_conf_id', tic.id);
        this.ticket_not_free = tic;
      }else{
        localStorage.setItem('ticket_free_conf_id', tic.id);
      }
    }
    this.ticketsQuantity = this.sharedService.totalTickets;
    this.maxTicket = this.selectedEvents[0].available_tickets;
    for(let event of this.selectedEvents){
      if(event.available_tickets < this.maxTicket){
        this.maxTicket = event.available_tickets;
      }
    }
    if(localStorage.getItem("pretty_location")){
      this.prettyLocation = localStorage.getItem("pretty_location");
    }else{
      this.prettyLocation = this.location;
    }
    localStorage.setItem('used_discount_code', null);
  }

  decrementQuantity(type) {
    this.discount_code = "";
    this.discount = "";
    this.total_amount = "";
    this.disabled_tooltip = true;
    localStorage.setItem('used_discount_code', null);
    this.matTooltip.hide();
    this.cdRef.detectChanges();

    if(!type){
      if (this.ticketsQuantity > 0) {
        this.ticketsQuantity--;
        this.cdRef.detectChanges();
      }
    }else{
      if (this.ticketsQuantityFree > 0) {
        this.ticketsQuantityFree--;
        this.cdRef.detectChanges();
      }
    }
  }
  incrementQuantity(type) {
    this.discount_code = "";
    this.discount = "";
    this.total_amount = "";
    localStorage.setItem('used_discount_code', null);
    if(!type){
      if (this.ticketsQuantity < this.maxTicket) {
        this.ticketsQuantity++;
        this.cdRef.detectChanges();
      }else{
        this.disabled_tooltip = false;
        this.matTooltip.show();
        this.cdRef.detectChanges();
      }
    }else{
      this.ticketsQuantityFree++;
      this.cdRef.detectChanges();
    }

  }
  decrementQty(ticket, type, ticketIndex, typeIndex, event) {

    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];


    // get check if qty between range
    const currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    // update qty of type
    typeRef.modelVal = currentQty - 1;

    // decrement total qty of ticket
    const currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    // Decrement TOTAL Ticket
    const currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);

    // check ticket total qty status
    const ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    // decrement ticket
    const cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: 'input__' + type.id,
      modelVal: typeRef.modelVal
    };

    this.formDataService.decrementTicketFromCart(ticket.id, cartType);

  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {

    const ticketRef = this.ticketsArrModified[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    // check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    // get check if qty between range
    const currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    // update qty
    typeRef.modelVal = currentQty + 1;

    // increment total qty of ticket
    const currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    // Increment TOTAL Ticket
    const currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);

    // check ticket total qty status
    const ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    // set / increment item to Cart[]
    const cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelName: 'input__' + type.id,
      modelVal: typeRef.modelVal
    };

    const cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType]
    };

    this.formDataService.incrementTicketToCart(cartTicket, cartType);

  }

  toggleElement(event) {
    const targetParent = event.currentTarget.parentElement;
    targetParent.classList.toggle('open');
    targetParent.children[1].classList.toggle('show');
  }

  checkInput(ele, ticketIndex, typeIndex) {

    const currentValue = parseInt(ele.value),
      modelValue = this.ticketsArrModified[ticketIndex].available_tickets;

    if (currentValue < 0 || currentValue > parseInt(modelValue)) {
      this.ticketsArrModified[ticketIndex].types[typeIndex].modelVal = 0;
    }
  }

  goToNext() {
      localStorage.setItem('total_tickets', this.ticketsQuantity.toString());
      localStorage.setItem('total_tickets_free', this.ticketsQuantityFree.toString());
      if(this.total_amount){
        localStorage.setItem('total_price', (this.total_amount).toString());
      }else{
        localStorage.setItem('total_price', (this.ticketsQuantity * this.ticket_not_free.price).toString());
      }
      this.router.navigateByUrl('/payment/' + this.location);
  }

  applyDiscountCode(){
    if(this.discount_code){
      var data = {
        "discount_code": this.discount_code,
        "attraction_id": localStorage.getItem('location_id'),
        "ticket_configuration_id":  localStorage.getItem('ticket_conf_id'),
        "tickets_quantity": this.ticketsQuantity
      }
      this.apiService.applyDiscountCode(data).subscribe(response => {

        this.total_amount = response.total_amount;
        this.discount = response.discount;
        localStorage.setItem('used_discount_code', this.discount_code);
        this.cdRef.detectChanges();

      },
      error =>{
        this.discount_code_error = error.error.error;
        this.cdRef.detectChanges();
      });
    }
  }

}
