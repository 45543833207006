import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule, Routes} from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// NGX
import {NgxSmartModalModule} from 'ngx-smart-modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import {AppComponent} from './app.component';
import {HeaderComponent} from './header/header.component';
import {TabsComponent} from './tabs/tabs.component';
import {TabDateComponent} from './tab-date/tab-date.component';
import {TabTypeComponent} from './tab-type/tab-type.component';
import {TabTicketsComponent} from './tab-tickets/tab-tickets.component';
import {FormDataService} from './data/form-data.service';
import {TabPaymentComponent} from './tab-payment/tab-payment.component';

// Guards
import {StepOneGuardService} from './guards/step-one-guard.service';
import {StepTwoGuardService} from './guards/step-two-guard.service';
import {StepThreeGuardService} from './guards/step-three-guard.service';
import {StepFourGuardService} from './guards/step-four-guard.service';

// Register Local Currency Types in 'IT'
import it from '@angular/common/locales/it';
import {registerLocaleData} from '@angular/common';
import {AppHolderComponent} from './app-holder/app-holder.component';
import { LocationsComponent } from './locations/locations.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { TabPaymentDetailsComponent } from './tab-payment-details/tab-payment-details.component';
import { RegisterationCompleteComponent } from './registeration-complete/registeration-complete.component';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { MatInputModule, MatButtonModule } from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';
import { CreditCardDirective } from './tab-payment-details/credit-card.directive';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SwiperModule, SwiperConfigInterface,
  SWIPER_CONFIG } from 'ngx-swiper-wrapper';
  import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {IvyCarouselModule} from 'angular-responsive-carousel';

registerLocaleData(it);

const appRoutes: Routes = [
  {
    path: 'locations/:location',
    component: HowItWorksComponent,
  },
  {
    path: '',
    component: HowItWorksComponent,
    pathMatch: 'full',
  },
  // {
  //   path: 'locations',
  //   component: LocationsComponent
  // },
  {
    path: 'locations',
    component: HowItWorksComponent,
  },
  {
    path: 'calendar/:location',
    component: TabDateComponent,
  },
  {
    path: 'tickets/:location',
    component: TabTicketsComponent,
    // canActivate: [StepThreeGuardService]

  },
  {
    path: 'payment/:location',
    component: TabPaymentComponent,
    // canActivate: [StepFourGuardService]

  },
  {
    path: 'payment-details',
    component: TabPaymentDetailsComponent
  },
  {
    path: 'registration-complete',
    component: RegisterationCompleteComponent
  },
  // {
  //   path: 'checkout',
  //   component: CheckoutComponent,
  //   canActivate: [CheckoutGuardService]
  // },
  {path: '**', redirectTo: '/locations'}
];

export const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  //threshold: 50,
  spaceBetween: 10,
  slidesPerView: 5,
  watchOverflow: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  breakpoints: {
    300: {
      slidesPerView: 1,
    },
    400: {
      slidesPerView: 1,
    },
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    1024: {
      slidesPerView: 4,
    },
    1400: {
      slidesPerView: 5,
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TabsComponent,
    TabDateComponent,
    TabTypeComponent,
    TabTicketsComponent,
    TabPaymentComponent,
    // CheckoutComponent,
    AppHolderComponent,
    LocationsComponent,
    HowItWorksComponent,
    TabPaymentDetailsComponent,
    RegisterationCompleteComponent,
    CreditCardDirective
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    FormsModule,
    HttpClientModule,
    NgxSmartModalModule.forRoot(),
    //CarouselModule.forRoot(),
    ProgressbarModule.forRoot(),
    JwSocialButtonsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    MatTooltipModule,
   // IvyCarouselModule,
    CreditCardDirectivesModule,
    SwiperModule,
    TranslateModule.forRoot(),
  ],
  exports: [
  ],
  providers: [
    StepTwoGuardService,
    StepThreeGuardService,
    StepFourGuardService,
    // CheckoutGuardService,
    {
      provide: FormDataService,
      useClass: FormDataService
    },
    {
      provide: LOCALE_ID,
      useValue: 'it-IT'
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
