export class Payment {
  number = "";
  exp_month = "";
  exp_year = "";
  cvc = "";
}

export class Order {
  attraction_id = "";
  user_name = "";
  user_surname = "";
  user_email = "";
  user_phone = "";
  user_zip = "";
  tax_code = "";
  country_id = 88;
  event_ids = new Array<string>();
  order_items = new Array<Object>();
  // order_item: {
  //   ticket_configuration_id: string,
  //   quantity: number
  // } = null;
  payment_data = new Payment();
}
