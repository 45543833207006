import {Component, OnInit, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {FormDataService} from '../data/form-data.service';
import {SharedDataService} from '../config/shared-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked {

  locationName: any;

  constructor(
    private formDataService: FormDataService,
    private sharedService: SharedDataService, private route: ActivatedRoute, private changeDetector : ChangeDetectorRef) {
  }

  ngOnInit() {
    this.sharedService.prettyLoc.subscribe(prettyLoc => this.locationName = prettyLoc);
    // this.sharedDataService.currentMessage.subscribe(data => this.locationName = data)
  }
  ngAfterViewChecked(){ this.changeDetector.detectChanges(); }
}
