import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Event, DateConfiguration } from '../Models/Event.model';
import {SharedDataService} from '../config/shared-data.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TicketService {

  // private API_URL = 'https://kalata-api.tandu.it';
  private API_URL = environment.API;

  constructor(private http: HttpClient,private sharedService: SharedDataService) { }
  getEvents(attraction_permalink: string) {
    return this.http.get(`${this.API_URL}/revelia_ticket/orders/init?attraction_permalink=${attraction_permalink}`).pipe(map((ev: any) => {
      if(ev.attraction){
        localStorage.setItem('location_id', ev.attraction.id);
        this.sharedService.order.attraction_id = ev.attraction.id;
        this.sharedService.prettyLocationObserve.next(ev.attraction.name);
        this.sharedService.prettyLocation = ev.attraction.name;
        localStorage.setItem('category', ev.attraction.category);
        this.sharedService.category = ev.attraction.category;
      }
      let events: Array<Event>;
      events = ev.events.map((e: Event | any) => {
        let dateConfig: DateConfiguration;
        dateConfig = e.date_configuration;
        dateConfig.guide = e.date_configuration.guide;
        return {
          ...e,
          date_configuration: dateConfig,
        };
      });
      return {events, tickets: ev.tickets, attraction: ev.attraction};
    }));
  }
}
