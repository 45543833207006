import { Component, OnInit } from "@angular/core";
import { FormDataService } from "../data/form-data.service";
import { SharedDataService } from "../config/shared-data.service";
import { ApiDataService } from "../api-data.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TicketService } from "../services/ticket.service";
import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.scss"],
})
export class HowItWorksComponent implements OnInit {
  public eventsExist: boolean = true;
  location = "";

  constructor(
    public formDataService: FormDataService,
    private sharedService: SharedDataService,
    private apiService: ApiDataService,
    private router: Router,
    private ticketService: TicketService,
    private route: ActivatedRoute
  ) {
    // this.disableUntil();

    this.formDataService.resetCourse();
  }

  ngOnInit() {
    // rest steps
    this.formDataService.resetActiveSteps();
    this.route.params.subscribe((params) => {
      this.sharedService.location = params.location;
      if (this.sharedService.location) {
        localStorage.setItem("location", this.sharedService.location);
      } else {
        let tmp_loc = localStorage.getItem("location");
        if (tmp_loc) {
          this.sharedService.location = tmp_loc;
        } else {
          // REDIRECT ONLY ON PROD
          // IF NEEDED, CAN BE SET ON STAGING TOO, CHANGE APP_BASE_URL ON STAGING ENVIRONMENT
          if (environment.production)
            window.location.href = `${environment.APP_BASE_URL}revelia/`;
        }
      }
      this.location = this.sharedService.location;
      this.sharedService.locationObserve.next(this.sharedService.location);
      this.ticketService
        .getEvents(this.sharedService.location)
        .subscribe((res) => {
          this.eventsExist = !!res.events.length
        },
        () => (this.eventsExist = false)
        );
    });
    // set active tab
    this.formDataService.setActiveStep("step_1", true);
    this.formDataService.setDisableStep("step_2", true);
  //  this.controlIfEventsExist();
  }

  goToNext(form: any) {
    this.router.navigate(["/calendar/" + this.sharedService.location]);
  }

  controlIfEventsExist() {
    if (!this.location) this.eventsExist = false;
    else
      this.ticketService.getEvents(this.location).subscribe(
        (data) => (this.eventsExist = !!data.events.length),
        () => (this.eventsExist = false)
      );
  }

  private saveStepData(form: any): boolean {
    if (!form.valid) {
      return false;
    }
    return true;
  }
}
