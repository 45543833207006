export class FormData {

  constructor() {
  }

  location: Object = {
    permalink: null,
    name: null
  };

  //step 1
  date: string = '';
  policyAccepted: boolean = false;

  //step 2
  course: Object = {
    id: null,
    created_at: null,
    updated_at: null,
    code: null,
    name: null,
    description: null
  };

  //step 3
  cart: Array<any> = [];

  //personal info
  user_name: string = '';
  user_email: string = '';
  user_phone: string = '';
  country_id: any = 0;
  user_zip: string = '';

  //cart total
  cartTotal: any = 0;

  clearAll() {

    this.location = {
      permalink: null,
      name: null
    };
    this.date = '';
    this.policyAccepted = false;
    this.course = {
      id: null,
      created_at: null,
      updated_at: null,
      code: null,
      name: null,
      description: null
    };
    this.cart = [];
    this.user_name = '';
    this.user_email = '';
    this.user_phone = '';
    this.country_id = 0;
    this.user_zip = '';

    this.cartTotal = 0;
  }

  clearLocation() {
    this.location = {
      permalink: null,
      name: null,
    };
  }

  clearCourse() {
    this.course = {
      id: null,
      created_at: null,
      updated_at: null,
      code: null,
      name: null,
      description: null
    };
  }

  clearCart() {
    this.cart = [];
  }

  clearPersonal() {
    this.user_name = '';
    this.user_email = '';
    this.user_phone = '';
    this.country_id = '';
    this.user_zip = '';
  }
}

export class Personal {

  constructor() {
  }

  user_name: string = '';
  user_email: string = '';
  user_phone: string = '';
  country_id: string = '';
  user_zip: string = '';
}

export class Calendar {
  constructor() {
  }

  selectedDate: any = '';
  policyAccepted: boolean = false;
}

export class Course {
  constructor() {
  }

  course: Object = {
    id: null,
    created_at: null,
    updated_at: null,
    code: null,
    name: null,
    description: null
  };
}
