import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {NgxSmartModalComponent, NgxSmartModalService} from 'ngx-smart-modal';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {FormDataService} from './data/form-data.service';
import { SharedDataService } from './config/shared-data.service';
import { TranslationService } from './config/translation.service';
import { locale as itLang } from './config/i18n/it';
//declare let fbq:Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  locations: any;
  modelSelection: any;

  constructor(
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {
    // router.events.subscribe((y: NavigationEnd) => {
    //   if(y instanceof NavigationEnd){
    //     fbq('track', 'PageView');
    //   }
    // })
    this.translationService.loadTranslations(itLang);
  }

  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   debugger;
    //   if(params.location){
    //     localStorage.setItem('location', params.location);
    //   }
    // this.router.navigate(['locations'])
    // });
  }

  selectedLocation(form) {
    // if (!form.invalid){
    //   this.formDataService.setLocationUrl({permalink: this.modelSelection, name: 'Foo'});
    //   this.ngxSmartModalService.getModal('kalataModalInfo').close();
    // }
  }

  ngAfterViewInit() {
    // this.route.params.subscribe(params => {
    //   debugger;
    //   if(params.location){
    //     localStorage.setItem('location', params.location);
    //   }
    //   this.router.navigate(['locations'])
    // });
    // // this.ngxSmartModalService.getModal('kalataModalInfo').onOpen.subscribe((modal: NgxSmartModalComponent) => {

    // });

    // this.ngxSmartModalService.getModal('kalataModalInfo').onAnyCloseEvent.subscribe((modal: NgxSmartModalComponent) => {
    //   this.modalRedirect(modal.getData().btnRedirect);
    // });

    // Needed to avoid "Expression has changed since the last check cycle..."
    // this.cdr.detectChanges();
  }

  private modalRedirect(route: string) {
    this.router.navigate([route]);
    return;
  }
}
