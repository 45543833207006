import { Injectable } from '@angular/core';
import { Order } from '../Models/Order.model';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import {map, catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {NgxSmartModalService} from 'ngx-smart-modal';
@Injectable({
  providedIn: 'root'
})
export class OrderService {

  //private API_URL = 'https://kalata-api.tandu.it';
  private API_URL = environment.API;

  private modalObj: any = {
    title: 'Si è verificato un errore!',
    icon: 'sad.png', // => assets/img/icons/ {name}
    btnText: 'Indietro',
    btnRedirect: '/'
  };

  constructor(private http: HttpClient, public ngxSmartModalService: NgxSmartModalService) { }
  public createCheckout(order: Object) {
    return this.http.post(this.API_URL + '/revelia_ticket/orders/checkout', order)
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj.title = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );
  }
}
