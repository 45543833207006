import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormDataService} from '../data/form-data.service';
import {SharedDataService} from '../config/shared-data.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  locations: Array<any> = [];

  locationInfo: any = {};

  isLoading = true;

  hasError = false;

  selectedOptionIndex: any = null;

  constructor(
    private formDataService: FormDataService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
    public router: Router) {

    const location = this.route.snapshot.queryParams['location'];

    // no location provided
    if (location !== undefined || (location && location === '')) {

      const locationData = {
        permalink: location
      };

      this.formDataService.setLocationUrl(locationData);
      this.formDataService.activeSteps.step_1 = true;
      this.router.navigate(['/calendar']);
      return;
    }

    this.formDataService.resetActiveSteps();

  }

  ngOnInit() {
    this.locationInfo = 0; // set disabled default


  }

  locationChange(ev) {
    this.selectedOptionIndex = ev.target.selectedIndex - 1;
  }

  addLocation(form) {

    if (!form.valid) {
      this.hasError = true;
      return;
    }

    if (this.selectedOptionIndex === null) {
      this.hasError = true;
      return;
    }

    const location = this.locations[this.selectedOptionIndex];

    const locationData = {
      id: location.id,
      permalink: location.permalink,
      name: location.name,
    };

    this.formDataService.setLocationUrl(locationData);
    this.sharedDataService.changeMessage(locationData.name);
    this.formDataService.activeSteps.step_1 = true;
    this.router.navigate(['/locations']);
  }

}
