import {Injectable} from '@angular/core';
import {CONFIG} from './config/config';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {FormDataService} from './data/form-data.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  locationURL = '';

  private headers: HttpHeaders;
  private API_URL = environment.API;

  private modalObj: any = {
    title: 'Si è verificato un errore!',
    icon: 'sad.png', // => assets/img/icons/ {name}
    btnText: 'Indietro',
    btnRedirect: '/'
  };

  constructor(private http: HttpClient, private router: Router,
              private formDataService: FormDataService,
              public ngxSmartModalService: NgxSmartModalService, ) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/json');

    this.locationURL = this.formDataService.getLocationUrl().permalink;
  }

  getCalendar(data): Observable<any> {

    return this.http.post(this.API_URL + '/locations/' + this.locationURL + '/search/calendar', data, {headers: this.headers}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj.title = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );

  }

  getCountries(): Observable<any> {

    return this.http.get(this.API_URL + '/countries').pipe(
      map((res: any) => res.countries),
      catchError(<T>(error: any, result?: T) => {
        return of(result as T);
      })
    );
  }

  getCourses(req: string): Observable<any> {

    const data = {
      date: req
    };

    return this.http.post(this.API_URL + '/locations/' + this.locationURL + '/search/courses', data, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj.title = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );
  }

  getTickets(req): Observable<any> {

    const data = {
      date: req.date,
      course_id: req.course.id
    };

    return this.http.post(this.API_URL + '/locations/' + this.locationURL + '/search/tickets', data, {headers: this.headers}).pipe(
      map((res: any) => res),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj.title = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );
  }

  applyDiscountCode(data): Observable<any> {

    return this.http.post(this.API_URL + '/revelia_ticket/orders/cost', data, {headers: this.headers}).pipe(
      map((res: any) => res)
    );
  }

  buyTickets(slots, user): Observable<any> {

    const data = {
      time_slots: slots,
      user_name: user.user_name,
      user_email: user.user_email,
      user_phone: user.user_phone,
      country_id: user.country_id,
      user_zip: user.user_zip,
      location_permalink: this.locationURL
    };

    return this.http.post(this.API_URL + '/cart/checkout', data, {headers: this.headers}).pipe(
      map((res: any) => {

        return {
          success: true,
          data: res
        };
      }),
      catchError(<T>(error: any, result?: T) => {

        if (error.error.errors) {
          this.modalObj.title = error.error.errors[0];
        }

        this.ngxSmartModalService.setModalData(this.modalObj, 'kalataModalInfo', true);

        this.ngxSmartModalService.open('kalataModalInfo');

        return of(result as T);
      })
    );
  }
}
