import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SharedDataService} from '../config/shared-data.service';
import {ApiDataService} from "../api-data.service";
import {FormDataService} from "../data/form-data.service";

@Component({
  selector: 'app-tab-type',
  templateUrl: './tab-type.component.html',
  styleUrls: ['./tab-type.component.scss']
})
export class TabTypeComponent implements OnInit {

  isLoading: boolean = true;

  availableCourses: Array<any> = [];
  apiData: any = {selectedDate: '', policyAccepted: ''};

  constructor(
    private formDataService: FormDataService,
    private sharedService: SharedDataService,
    public apiService: ApiDataService,
    private router: Router,
  ) {
    this.apiData = this.formDataService.getSelectedDate();
    //Clear cart
    this.formDataService.resetCart();
  }

  ngOnInit() {

    //remove active steps
    this.formDataService.setActiveStep('step_3', false);
    this.formDataService.setActiveStep('step_4', false);

    //get api Data
    this.apiService.getCourses(this.apiData.selectedDate).subscribe(data => {
      this.availableCourses = data.available_courses;
      this.isLoading = false;
    });

  }

  goToNext(course: any) {
    if (this.saveStepData(course)) {
      //ste active step 3 -> Guard
      this.formDataService.setActiveStep('step_3', true);
      this.router.navigate(['/tickets']);
    }
  }

  private saveStepData(course: any): boolean {
    if (!course) {
      return false;
    }
    this.formDataService.setCourseType(course);
    return true;
  }

}
