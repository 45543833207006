import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../config/shared-data.service';
import { Payment } from '../Models/Order.model';
import { OrderService } from '../services/order.service';
import {IMaskModule} from 'angular-imask';
import { Router, NavigationEnd } from '@angular/router';
//declare let fbq: Function;
@Component({
  selector: 'app-tab-payment-details',
  templateUrl: './tab-payment-details.component.html',
  styleUrls: ['./tab-payment-details.component.scss']
})
export class TabPaymentDetailsComponent implements OnInit {

  totalPrice = 1;
  date = '';
  iban_no;
  disableBtn: boolean;
  paymentDetails = new Payment();
  order = new Object();
  constructor(private sharedService: SharedDataService, private router: Router, private orderService: OrderService) { 
    // router.events.subscribe((y: NavigationEnd) => {
    //   if (y instanceof NavigationEnd) {
    //     fbq('track', 'SubmitApplication');
    //   }
    // })
  }

  ngOnInit() {
    this.totalPrice = this.sharedService.totalPrice;
    const d = new Date();
    // this.date = d.getFullYear() + '/';
    // if  ((d.getMonth() + 1) > 10) {
    //   this.date += (d.getMonth() + 1);
    // } else {
    //   this.date += '0' + (d.getMonth() + 1);
    // }
  }


  acceptPayment() {
    const dArr = this.date.split('/');
    this.paymentDetails.exp_year = (dArr[1]).trim();
    this.paymentDetails.exp_month = (dArr[0]).trim();

    this.paymentDetails.number = this.paymentDetails.number.substring(0, 19);
    this.disableBtn = true;
    this.order = JSON.parse(localStorage.getItem('order'));
    delete this.order["payment_data"];

    let obj = {
      order: this.order,
      payment_data: this.paymentDetails,
      discount_code: null
    };

    let used_discount_code = localStorage.getItem('used_discount_code');
    if(used_discount_code == 'null'){
      used_discount_code = null;
    }
    if(used_discount_code){
      obj = {
        order: this.order,
        payment_data: this.paymentDetails,
        discount_code: used_discount_code
      };
    }
    
    this.orderService.createCheckout(obj).subscribe((resp) => {
      this.disableBtn = false;
      if(resp["order"]){
        if(resp["events_dates"]){
          localStorage.setItem('events_dates', resp["events_dates"]);
        }
        if(resp["discount_code"]){
          localStorage.setItem('discount_code', resp["discount_code"]);
        }else{
          localStorage.setItem('discount_code', '');
        }
        localStorage.setItem('order', JSON.stringify(resp["order"]));
        //this.sharedService.saveSelected(resp["order"].events);
        this.router.navigateByUrl('/registration-complete');
      }else{
        return false;
      }

    })
  }

//   checkDigit() {
//     debugger;
//     var allowedChars = "0123456789";
//     var entryVal = (<HTMLInputElement>document.getElementById('txt_cardNumber')).value;
//     var flag;

//     for(var i=0; i<entryVal.length; i++){       
//         flag = false;

//         for(var j=0; j<allowedChars.length; j++){
//             if(entryVal.charAt(i) == allowedChars.charAt(j)) {
//                 flag = true; 
//             }
//         }

//         if(flag == false) { 
//             entryVal = entryVal.replace(entryVal.charAt(i),""); i--; 
//         }
//     }

//     return entryVal;
// }
}
