import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {CONFIG} from "./config";
import {catchError, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {NgxSmartModalService} from "ngx-smart-modal";
import { Event, Ticket } from '../Models/Event.model';
import { Order } from '../Models/Order.model';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  selectedEvents = new Array<Event>();
  ticket = new Ticket();
  location = '';
  totalTickets = 1;
  totalPrice = 0;
  order = new Order();
  privacyAccepted = false;
  private messageSource = new BehaviorSubject('');
  locationObserve = new Subject<string>();
  currentMessage = this.messageSource.asObservable();
  prettyLocationObserve = new Subject<string>();
  prettyLocation = "";
  category = "";


  constructor(
              public ngxSmartModalService: NgxSmartModalService,) {
  }

  changeMessage(data: string) {
    this.messageSource.next(data)
  }

  get loc() {
    return this.locationObserve.asObservable();
  }
  get prettyLoc() {
    return this.prettyLocationObserve.asObservable();
  }
  saveSelected(selectedEvents: Array<Event>) {
    this.selectedEvents = selectedEvents;
  }

  getSelected() {
    return this.selectedEvents;
  }

}
