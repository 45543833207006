import {Injectable} from '@angular/core';
import {FormDataService} from "../data/form-data.service";
import {Router} from "@angular/router";
import { SharedDataService } from '../config/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class StepFourGuardService {

  constructor(private formData: FormDataService,private sharedService: SharedDataService, public router: Router) {
  }

  canActivate(): boolean {
    // let cart = this.formData.getCart();
    const selectedEvents = this.sharedService.selectedEvents;
    if (!selectedEvents || selectedEvents.length === 0) {
      this.router.navigate(['/payment']);
      return false;
    }
    return true;
  }
}
