import { Component, OnInit } from "@angular/core";
import { ApiDataService } from "../api-data.service";
import { FormDataService } from "../data/form-data.service";
import { Router, NavigationEnd } from "@angular/router";
import { NgxSmartModalService } from "ngx-smart-modal";
import { Event, Ticket } from "../Models/Event.model";
import { SharedDataService } from "../config/shared-data.service";
import { SwiperOptions } from "swiper";
import { formatCurrency } from "@angular/common";
//declare let fbq: Function;

@Component({
  selector: "app-tab-payment",
  templateUrl: "./tab-payment.component.html",
  styleUrls: ["./tab-payment.component.scss"],
})
export class TabPaymentComponent implements OnInit {
  constructor(
    private apiService: ApiDataService,
    private formDataService: FormDataService,
    public ngxSmartModalService: NgxSmartModalService,
    private router: Router,
    private sharedService: SharedDataService
  ) {
    // router.events.subscribe((y: NavigationEnd) => {
    //   if (y instanceof NavigationEnd) {
    //     fbq("track", "AddToCart");
    //   }
    // });
  }

  countriesList: Array<any> = [];

  personalInfo: any = {};

  cartItems: Array<any> = [];

  cartTotal: any = 0;

  stepsData: any;

  isLoading = false;

  requiredCountryID = true;

  italyCountryID: any = 88; // Italy C_ID

  selectedEvents = new Array<Event>();
  totalTickets: number;
  totalTicketsFree: number;
  location = "";
  prettyLocation = "";
  totalPrice = 0;
  confirm_email_invalid:boolean = false;
  user_email_invalid: boolean = false;
  user_phone_invalid: boolean = false;
  email_regex: any;
  phone_regex: any;
  category: string;
  required_tax_code: boolean = false;

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: "horizontal",
    // slidesPerView: 3,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3,
      },
    },
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      hideOnClick: false,
    },
  };

  private static redirectToPayment(url: string) {
    window.location.href = url;
  }

  ngOnInit() {
    this.email_regex = /^(?!_)\w+([\.-]?\w+)*@(?!_)\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
    this.phone_regex = /^\d{7,15}$/;
    this.category = localStorage.getItem('category');
    if(this.category == 'abbonamento_musei'){
      this.required_tax_code = true;
    }else{
      this.required_tax_code = false;
    }

    // Set active tab
    this.formDataService.resetActiveSteps();

    this.formDataService.setActiveStep("step_4", true);
    this.formDataService.setDisableStep("step_3", false);
    this.formDataService.setDisableStep("step_5", true);

    if (localStorage.getItem("pretty_location")) {
      this.prettyLocation = localStorage.getItem("pretty_location");
    } else {
      this.prettyLocation = localStorage.getItem("location");
    }

    this.selectedEvents = JSON.parse(localStorage.getItem("selected_events"));

    this.totalTickets = parseInt(localStorage.getItem("total_tickets"));
    this.totalTicketsFree = parseInt(
      localStorage.getItem("total_tickets_free")
    );
    this.totalTickets = this.totalTickets + this.totalTicketsFree;

    this.location = localStorage.getItem("location");
    this.totalPrice = parseFloat(localStorage.getItem("total_price"));

    // Get countries:API
    this.apiService.getCountries().subscribe((data) => {
      this.countriesList = data;
      this.personalInfo.country_id = this.italyCountryID;
    });
  }

  countryChange(ev) {
    this.requiredCountryID = ev.target.value == this.italyCountryID;
  }

  decrementQty(ticket, type, ticketIndex, typeIndex, event) {
    const ticketData = this.formDataService.getTicketStructure();

    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    // get check if qty between range
    const currentQty = parseInt(typeRef.modelVal);
    if (currentQty <= 0) {
      return;
    }

    // update qty of type
    typeRef.modelVal = currentQty - 1;

    // decrement total qty of ticket
    const currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty - 1;

    // Decrement TOTAL Ticket
    const currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal - parseFloat(typeRef.price);

    // check ticket total qty status
    const ticketTotalQty = parseInt(ticketRef.calcData.totalQty);
    ticketRef.calcData.available = ticketTotalQty > 0;

    // decrement ticket
    const cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    this.formDataService.decrementTicketFromCart(ticket.id, cartType);

    // Get total
    this.cartTotal = this.formDataService.getCartTotal();

    // Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  incrementQty(ticket, type, ticketIndex, typeIndex, event) {
    const ticketRef = this.cartItems[ticketIndex],
      typeRef = ticketRef.types[typeIndex];

    // check if the total qty is not extending the tickets available
    if (parseInt(ticketRef.calcData.totalQty) >= ticket.available_tickets) {
      return;
    }

    // get check if qty between range
    const currentQty = parseInt(typeRef.modelVal);
    if (currentQty >= ticket.available_tickets) {
      return;
    }

    // update qty
    typeRef.modelVal = currentQty + 1;

    // increment total qty of ticket
    const currentTicketQty = ticketRef.calcData.totalQty;
    ticketRef.calcData.totalQty = currentTicketQty + 1;

    // Increment TOTAL Ticket
    const currentTotal = parseFloat(ticketRef.calcData.totalPrice);
    ticketRef.calcData.totalPrice = currentTotal + parseFloat(typeRef.price);

    // check ticket total qty status
    const ticketTotalQty = parseInt(ticketRef.calcData.totalQty);

    ticketRef.calcData.available = ticketTotalQty > 0;

    // set ticket
    const cartType = {
      id: type.id,
      name: type.name,
      price: type.price,
      modelVal: typeRef.modelVal,
    };
    const cartTicket = {
      id: ticket.id,
      created_at: ticket.created_at,
      updated_at: ticket.updated_at,
      date: ticket.date,
      start_at: ticket.start_at,
      end_at: ticket.end_at,
      available_tickets: ticket.available_tickets,
      calcData: ticketRef.calcData,
      types: [cartType],
    };
    this.formDataService.incrementTicketToCart(cartTicket, cartType);

    // Get total
    this.cartTotal = this.formDataService.getCartTotal();

    // Sync tickets Val with cart Val
    this.formDataService.syncTicketCartQty(ticket, type, typeRef.modelVal);
  }

  checkInput(ele, ticketIndex, typeIndex) {
    const cart = this.formDataService.getCart();

    const currentValue = parseInt(ele.value),
      modelValue = cart[ticketIndex].available_tickets;

    if (currentValue <= 0 || currentValue > parseInt(modelValue)) {
      // remove the type of this ticket
      this.formDataService.removeTypeTicket(
        false,
        false,
        ticketIndex,
        typeIndex
      );

      // update cartTotal ain view
      this.cartTotal = this.formDataService.getCartTotal();
    }
  }

  removeType(ticket, type, ticketInd, typeInd, event) {
    // remove types + ticket
    this.formDataService.removeTypeTicket(ticket, type, ticketInd, typeInd);

    // generate total price
    this.cartTotal = this.formDataService.getCartTotal();
  }
  goToPayment() {
    this.sharedService.order.user_email = this.personalInfo.user_email;
    this.sharedService.order.user_phone = this.personalInfo.user_phone;
    this.sharedService.order.user_name = this.personalInfo.user_name;
    this.sharedService.order.user_surname = this.personalInfo.user_surname;
    this.sharedService.order.user_zip = this.personalInfo.user_zip;
    this.sharedService.order.country_id = this.personalInfo.country_id;
    this.sharedService.order.tax_code = this.personalInfo.tax_code;
    this.selectedEvents.forEach((e) => {
      this.sharedService.order.event_ids.push(e.id);
    });


    // CONST
    const confs = [
      {
        quantity: localStorage.getItem("total_tickets"),
        ticket_configuration_id: localStorage.getItem("ticket_conf_id"),
      },
      {
        quantity: localStorage.getItem("total_tickets_free"),
        ticket_configuration_id: localStorage.getItem("ticket_free_conf_id"),
      },
    ];

    // ITEMS
    const items = [];
    for (let i = 0; i < confs.length; i++) {
      const el = confs[i];
      if (parseInt(el.quantity) > 0) {
        if (el.ticket_configuration_id != null) {
          items.push(el);
        }
      }
    }

    // SET
    this.sharedService.order.order_items = items;

    this.sharedService.order.attraction_id =
      localStorage.getItem("location_id");
    localStorage.setItem("order", JSON.stringify(this.sharedService.order));
    // this.sharedService.order.order_item = {
    //   quantity: this.sharedService.totalTickets,
    //   ticket_configuration_id: this.sharedService.ticket.id
    // };
    setTimeout(() => {
      this.router.navigateByUrl("/payment-details");
    }, 100);
  }

  payTickets(form) {
    this.isLoading = true;

    if (!form.valid) {
      if (form.control.controls.user_name.invalid) {
        form.form.controls.user_name.markAsDirty();
      }
      if (form.control.controls.user_surname.invalid) {
        form.form.controls.user_surname.markAsDirty();
      }

      if (form.control.controls.user_email.invalid) {
        form.form.controls.user_email.markAsDirty();
      }

      if (form.control.controls.tax_code.invalid) {
        form.form.controls.tax_code.markAsDirty();
      }

      if (form.control.controls.user_phone.invalid) {
        form.form.controls.user_phone.markAsDirty();
      }

      if (form.control.controls.country_id.value === 0) {
        form.form.controls.country_id.setErrors("invalid", true);
      }

      if (form.control.controls.user_zip.invalid) {
        form.form.controls.user_zip.markAsDirty();
      }

      this.isLoading = false;

      return;
    }

    // Set personal Data:FORM
    this.formDataService.setPersonal(this.personalInfo);

    // Do checkout
    this.doCheckout();
  }

  private doCheckout() {
    const cartItems = this.formDataService.getCart();

    if (!cartItems || cartItems.length <= 0) {
      const modalObj: Object = {
        title: "Something went wrong",
        icon: "sad.png", // => assets/img/icons/ {name}
        btnText: "Start again",
        btnRedirect: "/type",
      };

      // this.ngxSmartModalService.resetModalData('kalataModalInfo');
      this.ngxSmartModalService.setModalData(modalObj, "kalataModalInfo", true);

      this.ngxSmartModalService.open("kalataModalInfo");
      return;
    }

    const slots = [];

    cartItems.forEach((item) => {
      let _objTicket = {
        id: item.id,
        tickets: [],
      };

      const ticketItem = item;

      if (ticketItem.types && ticketItem.types.length > 0) {
        const typeArr = ticketItem.types;

        typeArr.forEach((type) => {
          let _objType = {
            quantity: type.modelVal,
            id: type.id,
          };

          _objTicket.tickets.push(_objType);
        });
      }

      // add ticket
      slots.push(_objTicket);
    });

    this.apiService
      .buyTickets(slots, this.personalInfo)
      .subscribe((response) => {
        if (response.success) {
          // Store response
          this.formDataService.setOrderedItems(response.data.order);

          this.isLoading = false;

          // Redirect to last page
          TabPaymentComponent.redirectToPayment(response.data.payment_url);

          // this.afterCheckout();
        }
      });
  }

  private afterCheckout() {
    // go to response view
    this.router.navigateByUrl("/payment-details");

    return;
  }

  validateEmail(event){
    this.user_email_invalid = !event.target.value.trim().match(this.email_regex);
  }


  validateConfirmEmail(event){
    this.confirm_email_invalid = !event.target.value.trim().match(this.email_regex);
  }

  validatePhone(event){
    //this.user_phone_invalid = !event.target.value.match(this.phone_regex);
    this.user_phone_invalid = !(new RegExp(this.phone_regex).test(event.target.value));
  }

  disableBtnSubmit(form) {
    return ((!form  || this.user_email_invalid || this.confirm_email_invalid || this.user_phone_invalid ||  this.personalInfo.user_email != this.personalInfo.confirm_email) || this.isLoading);
  }
}
