import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedDataService } from '../config/shared-data.service';
import { ApiDataService } from '../api-data.service';
import { Calendar } from '../data/formData.model';
import { FormDataService } from '../data/form-data.service';
import { IMyDpOptions, IMyDateModel, IMyCalendarViewChanged } from '../../my-date-picker/interfaces';
import { TicketService } from '../services/ticket.service';
import { Event, Ticket } from '../Models/Event.model';
import { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types/components/pagination';
import { throwToolbarMixedModesError } from '@angular/material';
import { SwiperComponent, SwiperDirective } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-tab-date',
  templateUrl: './tab-date.component.html',
  styleUrls: ['./tab-date.component.scss'],

})
export class TabDateComponent implements OnInit {

  @ViewChild('swiper') swiper?: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;

  public config: SwiperOptions = {
    a11y: { enabled: true },
    direction: 'horizontal',
    slidesPerView: 5,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    initialSlide: 0,
    navigation: true,
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    //   hideOnClick: false
    // }
  };



  constructor(
    public formDataService: FormDataService,
    private sharedService: SharedDataService,
    private apiService: ApiDataService,
    private router: Router,
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef) {

    // this.disableUntil();

    // this.formDataService.resetCourse();
  }
  isPolicyAccepted = false;
  activeSlide: any = {};
  itemsPerSlide = 5;
  events = new Array<Event>();
  tickets = new Array<Ticket>();
  selectedEvents = new Array<Event>();
  checkedEvents = new Array<boolean>();
  singleSlideOffset = true;
  slides;
  location = "";
  // *************************************************************************************************************************** //
  private date: Date = new Date();

  myDatePickerInlineOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    minYear: this.date.getFullYear(),
    maxYear: this.date.getFullYear(),
    inline: true,
    monthLabels: {
      1: 'Gennaio',
      2: 'Febbraio',
      3: 'Marzo',
      4: 'Aprile',
      5: 'Maggio',
      6: 'Giugno',
      7: 'Luglio',
      8: 'Agosto',
      9: 'Settembre',
      10: 'Ottobre',
      11: 'Novembre',
      12: 'Dicembre'
    },
    disableUntil: { year: this.date.getFullYear(), month: this.date.getMonth() + 1, day: this.date.getDay() - 1 },
    disableDays: [{ year: 0, month: 0, day: 0 }],
    selectorHeight: 'auto',
    selectorWidth: 'auto',
    allowSelectionOnlyInCurrentMonth: false,
    disableHeaderButtons: true,
    yearSelector: false,
    monthSelector: false,
    showTodayBtn: false,
  };
  localeIt: any = 'it';
  calendar: Calendar = {
    selectedDate: false, // moment().format("YYYY-MM-DD")
    policyAccepted: false
  };
  isLoading = false;
  policyChanged(event) {
    this.sharedService.privacyAccepted = this.isPolicyAccepted;
  }
  checkedTriggered(checkboxEvent, ev: Event) {
    if (this.selectedEvents.length === 3) {
      return;
    } else {
      if ((checkboxEvent.target as HTMLInputElement).checked && this.selectedEvents.length < 3) {
        let flag = false
        for (let event of this.selectedEvents) {
          if (event.id == ev.id) {
            flag = true;
            return;
          }
        }
        if (!flag) {
          this.selectedEvents.push(ev);
        }
      } else {
        const index = this.selectedEvents.findIndex(e => ev.id == e.id);
        if (index && index != -1) {
          this.selectedEvents.splice(index, 1);
        }
      }
    }
  }

  eventChecked(i, ev: Event) {
    debugger;
    if (ev.available_tickets == 0 || !this.checkDateAndHour(ev.date_configuration.date_event, ev.date_configuration.expire_at, ev.date_configuration.start_at || ev.status == "canceled")) {
      return;
    }
    else if (this.checkedEvents[i]) {
      const index = this.selectedEvents.findIndex(e => ev.id == e.id);
      this.selectedEvents.splice(index, 1);
      this.checkedEvents[i] = false;
      return;
    } else if (this.selectedEvents.length === 3) {
      return;
    }
    this.checkedEvents[i] = !this.checkedEvents[i];
    if (this.checkedEvents[i]) {
      this.selectedEvents.push(ev);
    }
  }

  ngOnInit() {
    // rest steps
    let tmp = localStorage.getItem("registration_completed");
    if (tmp == 'true') {

    }
    this.selectedEvents = new Array<Event>();
    this.formDataService.resetActiveSteps();
    this.isLoading = true;
    this.route.params.subscribe(params => {
      this.sharedService.location = params.location;
      this.location = this.sharedService.location;
    });
    this.ticketService.getEvents(this.location).subscribe(eve => {
      if (eve.attraction) {
        localStorage.setItem("pretty_location", eve.attraction.name);
      }
      this.isLoading = false;
      this.events = eve.events;
      this.tickets = eve.tickets;

      this.itemsPerSlide = this.itemsPerSlide > this.events.length ? this.events.length : this.itemsPerSlide;
      this.checkedEvents.fill(false, 0, this.events.length - 1);

      this.selectedEvents = this.sharedService.getSelected();
      if (this.selectedEvents && this.selectedEvents.length != 0) {
        for (const ev of this.selectedEvents) {
          const i = this.events.findIndex(e => ev.id == e.id);
          this.checkedEvents[i] = true;
        }
        this.isPolicyAccepted = true;
      }
      if (window.innerWidth < 500) {
        this.itemsPerSlide = 2;
      }
      else if (window.innerWidth == 1024) {
        this.itemsPerSlide = 4;
      }
      else if (window.innerWidth < 1024) {
        this.itemsPerSlide = 3;
      } else {
        this.itemsPerSlide = 5;
      }
      this.slides = new Array(Math.ceil(this.events.length / this.itemsPerSlide));

      setTimeout(function () {
        var prev = (document.querySelectorAll(".swiper-button-prev")[0]) as HTMLTextAreaElement;
        if (prev.classList.contains('swiper-button-disabled')) {
          (document.querySelectorAll(".swiper-button-prev")[1]).classList.add("disabled");
        }

        var next = (document.querySelectorAll(".swiper-button-next")[0]) as HTMLTextAreaElement;
        if (next.classList.contains('swiper-button-disabled')) {
          (document.querySelectorAll(".swiper-button-next")[1]).classList.add("disabled");
        }
      }, 500);

      //Forzo lo slider perchè inizi dal privo evento prenotabile
      for (const event of this.events) {
        if (this.checkDateAndHour(event.date_configuration.date_event, event.date_configuration.expire_at, event.date_configuration.start_at) && event.available_tickets > 0 && event.status != "canceled") {
          this.config["initialSlide"] = this.events.indexOf(event);
          this.cdRef.detectChanges();
          return;
        }
      };
    });

    // set active tab
    this.formDataService.setActiveStep('step_2', true);
    this.formDataService.setDisableStep('step_3', true);

  }

  slideNext(event) {
    var next = (document.querySelectorAll(".swiper-button-next")[0]) as HTMLTextAreaElement;
    next.click();
    this.handleDisable(next, event);

    (document.querySelectorAll(".swiper-button-prev")[1]).classList.remove("disabled");
  }
  slidePrev(event) {
    var prev = (document.querySelectorAll(".swiper-button-prev")[0]) as HTMLTextAreaElement;
    prev.click();
    this.handleDisable(prev, event);

    (document.querySelectorAll(".swiper-button-next")[1]).classList.remove("disabled");
  }

  handleDisable(el, event) {
    if (el.classList.contains('swiper-button-disabled')) {
      event.target.classList.add("disabled");
    } else {
      event.target.classList.remove("disabled");
    }
  }

  goToNext(form: any) {
    // console.log(this.selectedEvents);
    //this.sharedService.saveSelected(this.selectedEvents);
    localStorage.setItem('selected_events', JSON.stringify(this.selectedEvents));
    localStorage.setItem('tickets', JSON.stringify(this.tickets));
    this.router.navigate(['/tickets/' + this.location]);
  }

  onDateChanged(event: IMyDateModel) {
    this.calendar.selectedDate = event.formatted;
    this.formDataService.setSelectedDate(this.calendar);
  }

  onCalendarViewChanged(event: IMyCalendarViewChanged) {
    this.getDatesApi(event.month.toString(), event.year.toString());
  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerInlineOptions));
  }

  /**
   * Disable days till tomorrow
   */
  private disableUntil() {

    const d = new Date();

    d.setDate(d.getDate() - 1);

    const copy = this.getCopyOfOptions();

    copy.disableUntil = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };

    this.myDatePickerInlineOptions = copy;
  }

  private saveStepData(form: any): boolean {

    if (!form.valid) {
      return false;
    }

    this.formDataService.setSelectedDate(this.calendar);
    return true;
  }

  private getDatesApi(month, year) {

    const calendarPost = {
      month,
      year
    };

    this.apiService.getCalendar(calendarPost).subscribe(data => {
      this.disableDays(data.disabled_dates);
      this.sharedService.changeMessage(data.location.name);
    });

  }

  private disableDays(datesApi) {
    if (!datesApi) { return; }
    const copy = this.getCopyOfOptions();

    const newDates = [];

    datesApi.forEach(date => {

      const parseDate = new Date(date);

      const obj = {
        year: parseDate.getFullYear(),
        month: parseDate.getMonth() + 1,
        day: parseDate.getDate(),
      };

      newDates.push(obj);

    });

    copy.disableDays = newDates;
    this.myDatePickerInlineOptions = copy;
  }

  checkDate(date) {
    var today = new Date().toJSON().slice(0, 10);
    return date >= today;
  }

  checkDateAndHour(date_event, date_expire, start_at) {
    var d = new Date();
    var today = d.toJSON().slice(0, 10);
    var current_hour = this.addZeroBefore(d.getHours());
    var current_minute = this.addZeroBefore(d.getMinutes());
    var hour = current_hour + ':' + current_minute;
    if (today == date_expire) {
      return true;
    }
    return date_expire >= today;
  }

  addZeroBefore(n) {
    return (n < 10 ? '0' : '') + n;
  }


  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.ngOnInit();
  // }
}


