import {Component, OnInit} from '@angular/core';
import { SharedDataService } from '../config/shared-data.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-holder',
  templateUrl: './app-holder.component.html',
  styleUrls: ['./app-holder.component.scss']
})
export class AppHolderComponent implements OnInit {

  constructor(private sharedService: SharedDataService, private route: ActivatedRoute) {
  }

  ngOnInit() {
  }

}
